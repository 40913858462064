const StageOneBodyData = {
    items: [
        {
            label: 'Product',
            inputData: {
                id: 'product-name',
                placeholder: 'Productnaam',
            },
            configId: 'productName'
        },
        {
            label: 'Merk',
            inputData: {
                id: 'product-name',
                placeholder: 'Merknaam',
            },
            configId: 'brandName'
        },
        // {
        //     label: 'Verkoper',
        //     inputData: {
        //         id: 'company-name',
        //         placeholder: 'Bedrijfsnaam',
        //     },
        //     configId: 'seller'
        // },
    ]
}

export default StageOneBodyData