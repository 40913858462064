import React from 'react'
import Header from './Header'
import StageCounter from '../StageCounter'
import AllStages from '../stages/AllStages'
import { Outlet, useLocation } from 'react-router-dom'

function Layout({currentState, setCurrentState}) {
    const location = useLocation()
    console.log(location.pathname)
    const showStateCounter = currentState < 4 && !(location.pathname === '/test' || location.pathname === '/success' || location.pathname === '/cancel')
    return (
        <div className='flex flex-col gap-y-4'>
            <Header />
            <h1 className='text-center mt-8 font-semibold text-2xl'>{showStateCounter ? 'Bestellen' : ''}</h1>
            {showStateCounter && <StageCounter count={currentState} setCount={setCurrentState} />}
            <Outlet />
        </div>
    )
}

export default Layout