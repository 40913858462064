import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Validator from "../../utils/Validator"
import axios from 'axios'

const API_URL = 'https://wjrmnl23dwf2qjzjuwdqsjg5ti0jwkiq.lambda-url.us-east-1.on.aws'
// const API_URL = 'http://localhost:3001'

function Navigator({stage, setStage, allAnswers}) {
    const [isValid, setIsValid] = useState(false)
    const [isValidated, setIsValidated] = useState(false)
    const NUM_PAGES = 4
    const buttonStyle = 'w-24 h-12 bg-tumfur-green text-white uppercase rounded-md shadow-md shadow-tumfur-green'
    
    const handleNext = () => {
        const validator = new Validator(stage)
        const valid = validator.validate(allAnswers)
        if(!valid) return setIsValidated(true)
        setIsValid(true)
        if(stage === 3) {
            try {
                console.log(allAnswers)
                // Create Stripe checkout session
                fetch(`${API_URL}/create-checkout-session`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ allAnswers: allAnswers }),
                }).then(async response => {
                    const checkoutUrl = (await response.json()).url
                    window.location.href = checkoutUrl
                })
    
                // Upload photos to S3
                uploadPhotos(allAnswers.stageThree.file, allAnswers.stageOne.seller).then(() => {
                    console.log('Successfully uploaded images to S3 !')
                })
    
                // Save answers to database
                fetch(`${API_URL}/send-queue-message`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ messageBody: allAnswers }),
                }).then(() => console.log('Successfully sent data to the queue !'))         
                return 
            } catch(err) {
                console.error(err)
            }
        }
        setStage(stage < NUM_PAGES ? stage + 1 : NUM_PAGES)
        setIsValid(false)
        setIsValidated(false)
    }

    const handlePrev = () => {
        if(stage === 0) return
        setStage(() => stage - 1)
    }

    const uploadPhotos = async (photoList, clientName) => {
        photoList.forEach(async photo => {
            const photoRes = await fetch(photo)
            const blob = await photoRes.blob()
            const body = {data: blob, clientName: clientName, filename: photo.name}
            const res = await axios.post(API_URL + '/upload-images', body)
            console.log(res)
        })
    }

    return (
        <div className='w-full flex flex-row items-center justify-between'>     
            <button
                className={buttonStyle + (stage === 0 ? ' opacity-60' : '')}
                disabled={stage === 0}
                onClick={handlePrev}>Vorige</button>
            <span>
                {!isValid && isValidated && <b className="text-red-500 mx-4 uppercase">Niet alle velden zijn ingevuld</b>}
                <button
                    className={buttonStyle}
                    onClick={handleNext}>{stage !== 3 ? 'Volgende' : 'Bestellen'}</button>
            </span>
        </div>
    )
}

export default Navigator