import Input from "../layout/Input"
import StageTwoBodyData from "./body-data/StageTwoBodyData"
import { handleSetStageTwoAnswers } from "../../utils/SetAnswers"

function StageTwo({currentAnswers, answerSetter}) {
    const BODY_ITEMS = StageTwoBodyData.items
    console.log(currentAnswers)

    return (
        <div className={`grid grid-cols-2 grid-rows-${BODY_ITEMS.length / 2 + 1} gap-y-4 border-2 border-tumfur-main p-4 rounded-md shadow-xl`}>
            {BODY_ITEMS.map((bodyItem, index) => {
                return (
                    <div className='flex flex-col' key={index}>
                        <span className="flex flex-row">
                            <p>{bodyItem.heading}</p>
                            <p className="italic text-red-500 ml-2">{(bodyItem.multiple ? ' (meerdere antwoorden mogelijk)' : '') + (bodyItem.required ? ' *' : '')}</p>
                        </span>
                        {bodyItem.items.map((item, index) => {
                            return (
                                <span key={index} className='flex flex-row'>
                                    <Input 
                                        inputType={item.inputType} 
                                        id={item.id} 
                                        checked={currentAnswers[bodyItem.configId].indexOf(item.id) > -1}
                                        onChange={(e) => handleSetStageTwoAnswers(bodyItem.configId, e.target.checked, e.target.id, currentAnswers, answerSetter)}
                                        extraStyle={item.extraStyle ? item.extraStyle : ''} />
                                    <label htmlFor={item.id} className='flex flex-row gap-x-2'>
                                        <p>{item.value}</p>
                                        {item.price && <p className='italic'>{`€ ${item.price},-`}</p>}
                                        {/* {item.value.concat(item.price ? ` - € ${item.price},-` : '')} */}
                                    </label>
                                </span>
                            )
                        })}
                    </div>
                )
            })}
            <div></div>
            <p className='font-semibold'>Oneindige revisie totdat jij er tevreden mee bent!</p>
            <div className='flex w-full flex-row-reverse'>
                <p className='text-red-500'>* verplichte velden</p>
            </div>
        </div>
    )
}

export default StageTwo