import { useEffect, useState } from "react";
import Input from "../layout/Input"
// import PaymentBodyData from "./body-data/PaymentBodyData"
import CheckoutForm from "../checkout/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const PUBLIC_TEST_KEY = 'pk_test_VOOyyYjgzqdm8I3SrBqmh9qY'
const stripePromise = loadStripe(PUBLIC_TEST_KEY);

function Payment({allAnswers}) {
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        fetch("http://localhost:3001/create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
        })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    }, []);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    // const BODY_DATA = PaymentBodyData.items
    return (
        <div className='w-[90vw] mx-auto border-solid border-2 border-tumfur-main px-8 py-4 rounded-md shadow-lg'>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            )}
        </div>
    )
}

export default Payment