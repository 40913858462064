import UploadPhotos from "../UploadPhotos"
import StageThreeBodyData from "./body-data/StageThreeBodyData"
import Input from "../layout/Input"
import { handleSetAnswers, handleSetDimensions } from "../../utils/SetAnswers"

function StageThree({currentAnswers, answerSetter, clientName}) {
    const BODY_DATA = StageThreeBodyData.items
    console.log(currentAnswers)
    return (
        <div className={`grid grid-cols-2 grid-rows-${BODY_DATA.length / 2 + 1} gap-x-10 border-2 border-tumfur-main p-3 rounded-md shadow-xl`}>
            {BODY_DATA.map((item, index) => {
                if (item.inputs) {
                    return (
                        <span key={index} className='my-4'>
                            <p className='mb-2'>{item.heading + (item.required ? ' *' : '')}</p>
                            <span className='flex flex-row gap-x-8'>
                                {item.inputs.map((inputItem, index) => {
                                    return (
                                        <Input 
                                            key={1000 + index}
                                            inputType='text' 
                                            id={inputItem.id} 
                                            defaultValue={currentAnswers[item.configId][inputItem.id]}
                                            onChange={(e) => handleSetDimensions(inputItem.id, e.target.value, currentAnswers, answerSetter)}
                                            extraStyle='w-14 rounded-xl text-center'/>
                                    )
                                })}
                            </span>
                        </span>
                    )
                }

                if(item.type === 'textarea') {
                    return (
                        <span key={index} className='my-4'>
                            <p className='mb-2'>{item.heading + (item.required ? ' *' : '')}</p>
                            {/* <Input inputType={item.type} placeholder={item.placeholder} id={item.id}  extraStyle='w-full mt-2'/> */}
                            <textarea 
                                placeholder={item.placeholder}
                                defaultValue={currentAnswers[item.configId]}
                                onChange={(e) => handleSetAnswers(item.configId, e.target.value, currentAnswers, answerSetter)}
                                className='border-2 border-tumfur-main rounded-md resize-none w-full h-full p-2 focus:outline-none focus:border-tumfur-green'></textarea>
                        </span>
                    )
                }

                return (
                    <span key={index} className='my-4'>
                            <p className='mb-2'>{item.heading + (item.required ? ' *' : '')}</p>
                        <Input 
                            inputType='text' 
                            placeholder={item.placeholder} 
                            defaultValue={currentAnswers[item.configId]}
                            id={item.configId}  
                            onChange={(e) => handleSetAnswers(item.configId, e.target.value, currentAnswers, answerSetter)}
                            extraStyle='w-full'/>
                    </span>
                )
            })}
            <UploadPhotos clientName={clientName} currentAnswers={currentAnswers} answerSetter={answerSetter}/>
            <div className='col-span-2 flex flex-row-reverse'>
                <p className='col-span-2 mt-2 text-red-500'>* verplichte velden</p>
            </div>
        </div>
    )
}

export default StageThree