import './App.css';
import { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AllStages from './components/stages/AllStages';
import Payment from './components/stages/Payment';
import Layout from './components/layout/Layout';
import Summary from './components/checkout/Summary';
import Cancel from './components/checkout/Cancel';
import Success from './components/checkout/Success';

function App() {
    const [currentState, setCurrentState] = useState(0)

    // useEffect(() => {
    //     axios.get('url/get-stage-body-data')
    // }, [])  

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Layout currentState={currentState} />}>
                    <Route index element={<AllStages currentState={currentState} setCurrentState={setCurrentState}/>}/>
                    {/* <Route path='betalen' element={<Payment />} /> */}
                    <Route path='success' element={<Success />} />
                    <Route path='cancel' element={<Cancel />} />
                    {/* <Route path='test' element={<Success />} /> */}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
