const SummaryBodyData = {
    testOrder: {
        orderId: 1234,
        orderDate: (new Date()).toLocaleDateString(),
        totalCost: 100,
        paymentStatus: 'Pending'
    },
    testOrderDetails: [
        {
            label: 'Test Label 1',
            numItems: 10,
            price: 5,
            subTotal: 50
        },
        {
            label: 'Test Label 2',
            numItems: 10,
            price: 5,
            subTotal: 50
        },
        {
            label: 'Test Label 3',
            numItems: 10,
            price: 5,
            subTotal: 50
        },
    ]
}

export default SummaryBodyData