const ListingConfig = {
    stageOne: {
        answerTemplate: {productName: '', brandName: '', seller: ''}
    },
    stageTwo: {
        answerTemplate: {
            language: [],
            productListing: [],
            copyWriting: [],
            deliveryTime: [],
            marketplace: [],
            source: [],
            other: []
        }
    },
    stageThree: {
        answerTemplate: {
            style: '',
            usp: '',
            specs: '',
            listingExamples: '',
            linkToProduct: '',
            audience: '',
            dimensions: {length: -1, width: -1, height: -1},
            file: null
        }
    },
    stageFour: {
        answerTemplate: {
            contact: {
                firstName: '',
                lastName: '',
                email: ''
            },
            adress: {
                adressOne: '',
                adressTwo: '',
                city: '',
                province: '',
                postalCode: '',
                country: 'nederland',
                kvkNumber: ''
            }
        }
    }
}

export default ListingConfig