const StageTwoBodyData = {
    items: [
        {
            heading: 'Taal',
            items: [
                {
                    inputType: 'checkbox',
                    id: 'dutch-lang',
                    extraStyle: 'mr-2',
                    value: 'Nederlands'
                },
                {
                    inputType: 'checkbox',
                    id: 'english-lang',
                    extraStyle: 'mr-2',
                    value: 'Engels'
                }
            ],
            multiple: true,
            configId: 'language',
            required: true,
        },
        {
            heading: 'Marketplace',
            items: [
                {
                    inputType: 'checkbox',
                    id: 'bol-com',
                    extraStyle: 'mr-2',
                    value: 'Bol.com'
                },
                {
                    inputType: 'checkbox',
                    id: 'amazon',
                    extraStyle: 'mr-2',
                    value: 'Amazon'
                }
            ],
            configId: 'marketplace'
        },
        {
            heading: 'Product Listing',
            items: [
                {
                    inputType: 'checkbox',
                    id: 'mp-none',
                    extraStyle: 'mr-2',
                    value: 'Geen'
                },
                {
                    inputType: 'checkbox',
                    id: 'mp-basic',
                    extraStyle: 'mr-2',
                    value: 'Basis (4 afbeeldingen)',
                    price: 60
                },
                {
                    inputType: 'checkbox',
                    id: 'mp-premium',
                    extraStyle: 'mr-2',
                    value: 'Premium (6 afbeeldingen)',
                    price: 100
                },
                {
                    inputType: 'checkbox',
                    id: 'mp-pro',
                    extraStyle: 'mr-2',
                    value: 'Professional (8 afbeeldingen)',
                    price: 120
                },
            ],
            configId: 'productListing',
            required: true,
        },
        {
            heading: 'Bronmateriaal',
            items: [
                {
                    inputType: 'checkbox',
                    id: 'zelf-aanleveren',
                    extraStyle: 'mr-2',
                    value: 'Zelf aanleveren'
                },
                {
                    inputType: 'checkbox',
                    id: 'online-collect',
                    extraStyle: 'mr-2',
                    value: 'Online verzamelen',
                    price: 50
                },
            ],
            configId: 'source',
            required: true,
        },
        {
            heading: 'Copywriting',
            items: [
                {
                    inputType: 'checkbox',
                    id: 'cp-none',
                    extraStyle: 'mr-2',
                    value: 'Geen'
                },
                {
                    inputType: 'checkbox',
                    id: 'cp-tier-one',
                    extraStyle: 'mr-2',
                    value: 'Productomschrijving 300 woorden + SEO titel',
                    price: 25
                },
                {
                    inputType: 'checkbox',
                    id: 'cp-tier-two',
                    extraStyle: 'mr-2',
                    value: 'Productomschrijving 500 woorden + SEO titel',
                    price: 30
                },
            ],
            configId: 'copyWriting',
            required: true,
        },
        {
            heading: 'Levertijd',
            items: [
                {
                    inputType: 'checkbox',
                    id: 'delivery-12',
                    extraStyle: 'mr-2',
                    value: '12 werkdagen'
                },
                {
                    inputType: 'checkbox',
                    id: 'delivery-7',
                    extraStyle: 'mr-2',
                    value: '7 werkdagen',
                    price: 20
                },
                {
                    inputType: 'checkbox',
                    id: 'delivery-4',
                    extraStyle: 'mr-2',
                    value: '4 werkdagen',
                    price: 50
                },
            ],
            configId: 'deliveryTime',
            required: true,
        },
        {
            heading: 'Aanvullend',
            items: [
                {
                    inputType: 'checkbox',
                    id: 'verpakking',
                    extraStyle: 'mr-2',
                    value: 'Verpakking',
                    price: 120
                },
                {
                    inputType: 'checkbox',
                    id: 'handleiding',
                    extraStyle: 'mr-2',
                    value: 'Handleiding',
                    price: 100
                },
                {
                    inputType: 'checkbox',
                    id: 'logo',
                    extraStyle: 'mr-2',
                    value: 'Logo',
                    price: 75
                },
                {
                    inputType: 'checkbox',
                    id: 'huisstijl',
                    extraStyle: 'mr-2',
                    value: 'Huisstijl',
                    price: 100
                },
                {
                    inputType: 'checkbox',
                    id: 'ean',
                    extraStyle: 'mr-2',
                    value: 'EAN',
                    price: 100
                },
                {
                    inputType: 'checkbox',
                    id: 'ean-1',
                    extraStyle: 'mr-2',
                    value: 'EAN+',
                    price: 180
                },
                {
                    inputType: 'checkbox',
                    id: 'mock-up',
                    extraStyle: 'mr-2',
                    value: 'Mock up',
                    price: 20
                },
            ],
            multiple: true,
            configId: 'other'
        },
    ]
}

export default StageTwoBodyData