const StageFourBodyData = {
    items: {
        companyInfo: [
            {
                label: 'Voornaam',
                placeholder: 'Voornaam',
                configId: 'firstName'
            },
            {
                label: 'Achternaam',
                placeholder: 'Achternaam',
                configId: 'lastName'
            },
            {
                label: 'E-mail',
                placeholder: 'Emailadres',
                configId: 'email'
            },
            {
                label: 'Telefoonnummer',
                placeholder: 'Telefoonnummer',
                configId: 'phone-number'
            },
        ],
        adressInfo: [
            {
                label: 'Adresregel 1',
                placeholder: 'Adresregel 1',
                configId: 'adressOne'
            },
            {
                label: 'Adresregel 2',
                placeholder: 'Adresregel 2',
                configId: 'adressTwo'
            },
            {
                label: 'Stad',
                placeholder: 'Stad',
                configId: 'city'
            },
            {
                label: 'Provincie',
                placeholder: 'Provincie',
                configId: 'province'
            },
            {
                label: 'Postcode',
                placeholder: '1234AB',
                configId: 'postalCode'
            },
            {
                label: 'Land',
                placeholder: '--Selecteer een land--',
                selectOptions: [
                    'Nederland',
                    'Belgie',
                ]
            },
            {
                label: 'KvK-nummer',
                placeholder: 'Zoals je ingeschreven staat bij de Kamer van Koophandel',
                configId: 'kvkNumber'
            }
        ]
    },
}


export default StageFourBodyData