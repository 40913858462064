import axios from 'axios'
import { useState } from 'react';
import { handleSetAnswers } from '../utils/SetAnswers';

function UploadPhotos({clientName, currentAnswers, answerSetter}) {
    const [photoList, setPhotoList] = useState([])

    const uploadPhotos = async () => {
        photoList.forEach(async photo => {
            const photoRes = await fetch(photo)
            const blob = await photoRes.blob()
            const body = {data: blob, clientName: clientName, filename: photo.name}
            const res = await axios.post('https://wjrmnl23dwf2qjzjuwdqsjg5ti0jwkiq.lambda-url.us-east-1.on.aws/upload-images', body)
            console.log(res)
        })
    }

    const convertToBase64 = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            }
        })
    }

    const handleUploadImages = (file) => {
        if(!file || file === undefined || file.target.files.length < 1) return
        console.log(file.target.files)
        setPhotoList([...photoList, ...file.target.files])
        handleSetAnswers('file', [...photoList, ...file.target.files], currentAnswers, answerSetter)
        console.log(currentAnswers)
    }
    
    return (
        <div>
            <input type='file' multiple accept='image/*' id='photo-input' name='photo-input' onChange={file => handleUploadImages(file)} />
            {/* <button 
                onClick={() => uploadPhotos()}
                className='bg-tumfur-green text-white w-24 p-2 rounded-md shadow-lg border-2 border-tumfur-main'>UPLOAD</button> */}
        </div>
    )
}

export default UploadPhotos