const StageThreeBodyData = {
    items: [
        {
            heading: 'Gewenste stijl',
            type: 'textarea',
            configId: 'style'
        },
        {
            heading: 'Link naar product',
            placeholder: 'Link naar product van de leverancier of jouw bestaande listing',
            configId: 'linkToProduct'
        },
        {
            heading: 'USP\'s',
            placeholder: 'Wat maakt dit product goed? Noem een paar sterke punten met een beknopte uitleg',
            type: 'textarea',
            configId: 'usp'
        },
        {
            heading: 'Doelgroep',
            placeholder: 'Aan wie wil je dit product verkopen?',
            configId: 'audience'
        },
        {
            heading: 'Specificaties',
            placeholder: 'Alle technische specificaties van jouw product. Denk hierbij aan zaken zoals het materiaal, de kleur, extra functies, batterijen, wattage, lichtsterkte, extra garanties etc.',
            type: 'textarea',
            configId: 'specs'
        },
        {
            heading: 'Afmetingen',
            inputs: [
                { id: 'length' },
                { id: 'width' },
                { id: 'height' },
            ],
            configId: 'dimensions',
            required: true,
        },
        {
            heading: 'Listing voorbeeld(en)',
            placeholder: 'Geef een linkje naar één of meerdere listing(s) die jij een 10 zou geven!',
            configId: 'listingExamples'
        }
    ]
}

export default StageThreeBodyData