import React from 'react'
import { useNavigate } from 'react-router-dom'

function Success() {
    const navigate = useNavigate()

    const handleBackHome = () => {
        navigate('/')
    }

    return (
        <div className='flex flex-col justify-center text-center'>
            <div className='w-[50vw] flex flex-col border-2 border-tumfur-main mx-auto pt-2 pb-10 px-4 shadow-xl rounded-md'>
                <h1 className='font-bold text-xl my-4'>Bestelling voltooid!</h1>
                <p>Bedankt voor je bestelling. Je hoort zo snel mogelijk van ons!</p>
                <button className='w-[60%] h-20 bg-tumfur-green text-white rounded-lg shadow-lg mx-auto mt-8' onClick={handleBackHome}>TERUG NAAR HOME</button>
            </div>
        </div>
    )
}

export default Success