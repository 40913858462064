const StageOneBodyData = require('../components/stages/body-data/StageOneBodyData')
const StageTwoBodyData = require('../components/stages/body-data/StageTwoBodyData')
const StageThreeBodyData = require('../components/stages/body-data/StageThreeBodyData')
const StageFourBodyData = require('../components/stages/body-data/StageFourBodyData')

export default class Validator {
    constructor(stage) {
        console.log('Validator.js: Successfully created new instance of Validator.js !')
        this.stage = stage
    }

    validate = (answers) => {
        // STAGE 1
        if(this.stage === 0) {
            const stageOneAnswers = answers.stageOne
            return (
                stageOneAnswers.productName !== ''
                && stageOneAnswers.brandName !== ''
            )
        }

        // STAGE 2
        if(this.stage === 1) {
            const stageTwoAnswers = answers.stageTwo
            return (
                stageTwoAnswers.language.length > 0
                && stageTwoAnswers.productListing.length === 1
                && stageTwoAnswers.source.length === 1
                && stageTwoAnswers.copyWriting.length === 1
                && stageTwoAnswers.deliveryTime.length === 1
                // && stageTwoAnswers.other.length > 0
            )
        }

        // STAGE 3
        if(this.stage === 2) {
            // const dimensionAnswers = answers.stageThree.dimensions
            // return dimensionAnswers.height > -1 && dimensionAnswers.width > -1 && dimensionAnswers.length > 0
            return true
        }

        // STAGE 4
        if(this.stage === 3) {
            const stageFourAnswers = answers.stageFour
            return Object.values(stageFourAnswers.adress).indexOf('') === -1 || Object.values(stageFourAnswers.contact).indexOf('') === -1
        }
    }
}
