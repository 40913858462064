import { handleSetStageFourAnswers } from "../../utils/SetAnswers"
import Input from "../layout/Input"
import StageFourBodyData from "./body-data/StageFourBodyData"

function StageFour({currentAnswers, answerSetter}) {
    const BODY_DATA = StageFourBodyData.items
    return (
        <div className='flex flex-col border-2 border-tumfur-main p-4 rounded-md shadow-xl'>
            <b className='mb-2'>Contact</b>
            <div className='grid grid-cols-2 gap-x-10 gap-y-4'>
                {BODY_DATA.companyInfo.map((companyInfoItem, index) => {
                    return (
                        <span key={index} className='grid grid-cols-[2fr_5fr]'>
                            <label>{companyInfoItem.label} *</label>
                            <Input 
                                inputType='text' 
                                defaultValue={currentAnswers[companyInfoItem.configId]}
                                placeholder={companyInfoItem.placeholder} 
                                id={companyInfoItem.label.toLowerCase()}
                                onChange={(e) => handleSetStageFourAnswers(companyInfoItem.configId, 'contact', e.target.value, currentAnswers, answerSetter)}
                                extraStyle='w-full'/>
                        </span>
                    )
                })}
            </div>
            <b className='mt-4 mb-2'>Adres</b>
            <div className='grid grid-cols-2 gap-x-10 gap-y-4'>
                {BODY_DATA.adressInfo.map((adressInfoItem, index) => {
                    if (adressInfoItem.selectOptions) {
                        return (
                            <span key={100 + index} className='grid grid-cols-[2fr_5fr]'>
                                <label>{adressInfoItem.label} *</label>
                                <select className='border-2 border-tumfur-main rounded-md focus:outline-none focus:border-tumfur-green'>
                                    {adressInfoItem.selectOptions.map((selectOption, index) => {
                                        return (
                                            <option key={1000 + index} id={selectOption.toLowerCase()} name={selectOption.toLowerCase()}>{selectOption}</option>
                                        )
                                    })}
                                </select>
                            </span>
                        )
                    }
                    return (
                        <span key={100 + index} className='grid grid-cols-[2fr_5fr]'>
                            <label>{adressInfoItem.label} *</label>
                            <Input 
                                inputType='text' 
                                placeholder={adressInfoItem.placeholder} 
                                defaultValue={currentAnswers[adressInfoItem.configId]}
                                onChange={(e) => handleSetStageFourAnswers(adressInfoItem.configId, 'adress', e.target.value, currentAnswers, answerSetter)}
                                id={adressInfoItem.label.toLowerCase()} />
                        </span>
                    )
                })}
            </div>
            <span className='mt-2'>
                <Input 
                    inputType='checkbox' 
                    id='algemene-voorwaarden' 
                    extraStyle='mr-2 mt-4' />
                <label htmlFor='algemene-voorwaarden'>Ik ga akkoord met de <a href='#'>algemene voorwaarden *</a></label>
            </span>
            <div className='flex flex-row-reverse'>
                <p className='mt-2 text-red-500'>* verplichte velden</p>
            </div>   
        </div>
    )
}

export default StageFour