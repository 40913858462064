function Input({ inputType, placeholder, defaultValue, id, extraStyle, onChange }) {
    return (
        <input
            className={`border-2 border-tumfur-main p-2 rounded-md ${extraStyle} focus:border-tumfur-green focus:outline-none`}
            onChange={(e) => onChange(e)}
            type={inputType}
            name={id}
            id={id}
            defaultValue={defaultValue}
            placeholder={placeholder}></input>
    )
}

export default Input