import { useState } from "react"

function Header() {
    const [help, setHelp] = useState()
    const tumfurLogo = null
    const helpIcon = null
    return (
        <header className={`flex w-full h-24 bg-tumfur-main justify-between items-center ${help ? 'opacity-60' : ''}`}>
            <img src={tumfurLogo} className='h-16 ml-4'></img>
            <span className='flex flex-row items-center'>
                <a href='https://tumfur.nl' className='uppercase mr-8 text-white font-bold hover:text-gray-400'>Naar de website</a>
                <button onClick={() => setHelp(!help)}>
                    <img src={helpIcon} className='h-10 mr-4 '></img>
                </button>
            </span>
        </header>
    )
}

export default Header