export const handleSetAnswers = (configId, change, currentAnswers, answerSetter) => {
    const tempAnswers = currentAnswers
    tempAnswers[configId] = change
    answerSetter(tempAnswers)
}

export const handleSetStageTwoAnswers = (configId, checked, change, currentAnswers, answerSetter) => {
    const tempAnswers = currentAnswers
    const answerFields = tempAnswers[configId]
    const answerIndex = answerFields.indexOf(change)
    let newAnswerFields = []
    if(checked) {
        if(answerIndex === -1) {
            newAnswerFields = [...answerFields, change]
        }
    } else {
        newAnswerFields = answerFields.toSpliced(answerIndex, 1)
    }
    tempAnswers[configId] = newAnswerFields
    answerSetter(tempAnswers)
}

export const handleSetStageFourAnswers = (configId, category, change, currentAnswers, answerSetter) => {
    const tempAnswers = currentAnswers
    tempAnswers[category][configId] = change
    answerSetter(tempAnswers)
}

export const handleSetDimensions = (configId, change, currentAnswers, answerSetter) => {
    const tempAnswers = currentAnswers
    tempAnswers.dimensions[configId] = change
    answerSetter(tempAnswers)
}


