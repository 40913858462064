import React, {useEffect, useState} from 'react'
import StageOne from './StageOne'
import StageTwo from './StageTwo'
import StageThree from './StageThree'
import StageFour from './StageFour'
import Navigator from '../layout/Navigator';
import ListingConfig from '../../ListingConfig';


function AllStages({currentState, setCurrentState}) {
    const [stageOneAnswers, setStageOneAnswers] = useState(ListingConfig.stageOne.answerTemplate)
    const [stageTwoAnswers, setStageTwoAnswers] = useState(ListingConfig.stageTwo.answerTemplate)
    const [stageThreeAnswers, setStageThreeAnswers] = useState(ListingConfig.stageThree.answerTemplate)
    const [stageFourAnswers, setStageFourAnswers] = useState(ListingConfig.stageFour.answerTemplate)

    return (
        <div className='flex flex-col gap-y-4 px-10 py-4'>
            {currentState === 0 && <StageOne currentAnswers={stageOneAnswers} answerSetter={setStageOneAnswers} />}
            {currentState === 1 && <StageTwo currentAnswers={stageTwoAnswers} answerSetter={setStageTwoAnswers} />}
            {currentState === 2 && <StageThree currentAnswers={stageThreeAnswers} answerSetter={setStageThreeAnswers} clientName={stageOneAnswers.seller}/>}
            {currentState === 3 && <StageFour currentAnswers={stageFourAnswers} answerSetter={setStageFourAnswers} />}
            {currentState < 4 && <Navigator 
                stage={currentState} 
                setStage={setCurrentState} 
                allAnswers={{stageOne: stageOneAnswers, stageTwo: stageTwoAnswers, stageThree: stageThreeAnswers, stageFour: stageFourAnswers}} />}
        </div>
    )
}

export default AllStages