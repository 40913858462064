import LoadingBar from 'react-top-loading-bar'

function StageCounter({ count, setCount }) {
    const MAX_COUNT = 4

    return (
        <div className='w-full px-10'>
            <p>Stap {count + 1} van {MAX_COUNT}</p>
            <LoadingBar
                color='#44AC97'
                progress={(100 * (count + 1) ) / MAX_COUNT}
                // onLoaderFinished={() => setCount(0)}
                height={8}
            />
            {/* <div className='w-full h-4 border-2 border-tumfur-main rounded-lg overflow-hidden my-2'>
                <div className={`w-[${percentage}] h-full bg-tumfur-green`}></div>
            </div> */}
        </div>
    )
}

export default StageCounter