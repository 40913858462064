import Input from "../layout/Input"
import StageOneBodyData from "./body-data/StageOneBodyData"
import { handleSetAnswers } from "../../utils/SetAnswers"

function StageOne({currentAnswers, answerSetter}) {
    const BODY_DATA = StageOneBodyData.items
    console.log(currentAnswers)
    
    return (
        <div className='grid grid-cols-2 gap-x-10 border-2 border-tumfur-main p-4 rounded-md shadow-xl'>
            {BODY_DATA.map((item, index) => {
                return (
                    <span className='flex flex-col my-2' key={index}>
                        <label className='text-lg' htmlFor={item.inputData.id}>{item.label} *</label>
                        <Input 
                            inputType='text' 
                            id={item.inputData.id} 
                            defaultValue={currentAnswers[item.configId]}
                            placeholder={item.inputData.placeholder} 
                            onChange={(e) => handleSetAnswers(item.configId, e.target.value, currentAnswers, answerSetter)} />
                    </span>
                )
            })}
            <div className='col-span-2 flex flex-row-reverse'>
                <p className='mt-2 text-red-500'>* verplichte velden</p>
            </div>        
        </div>
    )
}

export default StageOne